import Vue from 'vue'
import VueMeta from 'vue-meta'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChartPie, faSackDollar, faSterlingSign } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueExcelXlsx from "vue-excel-xlsx";

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueExcelXlsx);

Vue.use(VueMeta)

library.add(faSackDollar)
library.add(faSterlingSign)
library.add(faChartPie)

Vue.component('font-awesome-icon', FontAwesomeIcon);

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import('@fortawesome/free-solid-svg-icons').then((icons) => {
//   for (const key in icons) {
//     // console.log('icons with key ', icons[`${key}`]);
//     // console.log('icons with key str ', key);
//     try {
//       library.add(icons[`${key}`]);
//     } catch (error) {
//       console.log('Error ', error);
//     }

//   }
// });

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
