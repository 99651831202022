import axiosInstance from "./Api";

export default {

  async getSingleUser(payload) {
    const { user_id } = payload;
    let response = await axiosInstance().get(`/users/getSingleUser/${user_id}`);
    return response;
  },

  async saveUser(payload) {
    let response = await axiosInstance().post("/users/saveUsers", payload);
    return response;
  },

  async deleteUser(payload) {
    let response = await axiosInstance().post("/users/deleteUser", payload);
    console.log(response);
    return response;
  },

  async getAllUsers() {
    let response = await axiosInstance().get(`/users/getAllUsers`);
    return response;
  },

  async getUsers() {
    let response = await axiosInstance().get(`/users/getUsers`);
    return response;
  },

  async getAdmins() {
    let response = await axiosInstance().get(`/users/getAdmin`);
    return response;
  },

  async saveVatGst(payload) {

    let response = await axiosInstance().post(`/users/save-vat-gst`, payload);

    return response;
  },

  async getUserProfile(payload) {

    let response = await axiosInstance().get(`/users/user-profile`);

    return response;
  },

  async saveSettingsPersonal(payload) {

    let response = await axiosInstance().post(`/users/saveSettingsPersonal`, payload);

    return response;
  },

  async updateComission(payload) {

    let response = await axiosInstance().post(`/users/updateComission`, payload);

    return response;
  },

};
