<template>
  <div>
    <b-container>
      <b-row class="cookies_contant align-items-center">
        <b-col md="10">
          Cookies are small text files that websites place on the computers people who visit those websites.
        </b-col>
        <b-col md="2" class="text-center">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            type="button"
            class="btn btn-outline-primary"
          >
            Accept
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { BContainer, BRow, BCol, BButton } from "bootstrap-vue";
export default {
  name: "CookiesContent",
  components: {
    BContainer,
    BRow,
    BCol,
    BButton
  },
};
</script>
<style >
.cookies_contant {
  padding: 1rem;
  background: #e1d7ce;
  font-size: 1.2rem;
  position: fixed;
  bottom: 15%;
  z-index: 9999;
  color: #3a4b43;
  border-radius: 0.5rem;
  transform: translate(-50%, 50%);
  left: 50%;
  width: 80%;
}
</style>
