<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
    <!-- <CookiesContent /> -->
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { useWindowSize, useCssVar } from "@vueuse/core";
import CookiesContent from "@core/components/cookies/CookiesContent.vue";

import store from "@/store";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    CookiesContent,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
  beforeMount() {
    const userDetails = localStorage.getItem("userInfo");
    if (userDetails) {
      this.$store.commit("user/SET_USER_DETAILS", JSON.parse(userDetails));
    }

    /* if (location.hostname != "localhost" || location.hostname != "127.0.0.1") {
      console.log(location.hostname);
      if (location.protocol !== "https:" && location.hostname != "localhost") {
        location.replace(
          `https:${location.href.substring(location.protocol.length)}`
        );
      }
    } */

    if (location.protocol !== "https:" && location.hostname != "localhost") {
      location.replace(
        `https:${location.href.substring(location.protocol.length)}`
      );
    }

    /* if (window.location.href.indexOf("www") > -1) {
      var ogurl = `${window.location.href}`;
      var split = ogurl.split("//");
      var newurl = "https://www." + split[1];
      console.log(`${window.location.href}`);
      console.log(newurl);
      location.replace(newurl);
    } else {
      console.log("else www");
    } */

    if (window.location.href.indexOf("www") > -1) {
      var ogurl = `${window.location.href}`;
      var split = ogurl.split("//");
      var newurl = "https://www." + split[1].replace("www.", "");
      console.log("OG_URL: ", `${window.location.href}`);
      console.log("NEW_URL", newurl);
      //location.replace(newurl);
    } else {
      console.log("else www", `${window.location.href}`);

      var ogurl = `${window.location.href}`;
      var split = ogurl.split("//");
      var newurl = split[0] + "//www." + split[1].replace("www.", "");
      window.location.href = newurl
      console.log("newurl : ", newurl)

    }

  },
};
</script>



<style>
/* [dir="ltr"] .main-menu.menu-light .navigation > li.active > a {
  background: linear-gradient(118deg, #ff9f43, #ff9f43) !important;
} */

@media screen {
  @font-face {
    font-family: "Arboria-Book";
    src: url("https://revivify-new.s3.ap-south-1.amazonaws.com/fonts/1c7f14203d028f1c6f8914508f469cb9.eot"); /* IE9*/
    src: url("https://revivify-new.s3.ap-south-1.amazonaws.com/fonts/1c7f14203d028f1c6f8914508f469cb9.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-IE8 */
        url("https://revivify-new.s3.ap-south-1.amazonaws.com/fonts/1c7f14203d028f1c6f8914508f469cb9.woff2")
        format("woff2"),
      /* chrome firefox */
        url("https://revivify-new.s3.ap-south-1.amazonaws.com/fonts/1c7f14203d028f1c6f8914508f469cb9.woff")
        format("woff"),
      /* chrome firefox */
        url("https://revivify-new.s3.ap-south-1.amazonaws.com/fonts/1c7f14203d028f1c6f8914508f469cb9.ttf")
        format("truetype"),
      /* chrome firefox opera Safari, Android, iOS 4.2+*/
        url("https://revivify-new.s3.ap-south-1.amazonaws.com/fonts/1c7f14203d028f1c6f8914508f469cb9.svg#Arboria-Book")
        format("svg"); /* iOS 4.1- */
  }

  /* @font-face{
				font-family:'Arboria-Book';
				font-style:normal;
				font-weight:400;
				src:local('Arboria-Book'), local('OpenSans'), url('https://fonts.gstatic.com/s/opensans/v10/cJZKeOuBrn4kERxqtaUH3bO3LdcAZYWl9Si6vvxL-qU.woff') format('woff');
			} */
}

html,
body,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
table {
  font-family: "Arboria-Book" !important;
}

[dir="ltr"] .main-menu.menu-light .navigation > li.active > a {
  font-weight: 600;
  color: #a96a37;
  background: #e4d4bb !important;
  box-shadow: none !important;
}

[dir] .btn-primary {
  color: #a96a37;
  border-color: #a96a37 !important;
  background-color: #e4d4bb !important;
}

/* [dir] .card {
    border: none;
    margin-bottom: 2rem;
    box-shadow: 0 0 0 0 #ffff !important;
    background-color: #f7f7f7 !important;
    background-clip: border-box !important;
} */

/* .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.active a {
    color: #fff !important;
} */
</style>
