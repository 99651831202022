// LIVE
// export const BASEURL = "https://miraj-api.binarynumbers.io/api";
// export const IMAGEURL = "https://miraj-api.binarynumbers.io";

// DEV
//AJAY Mac - http://127.0.0.1:8003/api
//Singhar IP - http://192.168.1.204:8003/api
//export const BASEURL = "http://localhost:8003/api";
//export const BASEURL = "http://127.0.0.1:8003/api";


//export const BASEURL = process.env.VUE_APP_BASEURL;
//export const FILESURL = process.env.VUE_APP_FILESURL;

// DEV
export const BASEURL = "https://api.revivify.com/api";
export const IMAGEURL = "https://api.revivify.com/uploads/images/";
export const PROMOTIONS_IMAGEURL =
  "https://api.revivify.com/uploads/images/promotions/";
export const BANNERS_IMAGEURL = "https://api.revivify.com/uploads/images/";
export const MOVIES_IMAGEURL = "https://api.revivify.com/uploads/images/movies/";
export const CINEMAS_IMAGEURL = "https://api.revivify.com/uploads/images/cinemas/";
export const SCREENTYPES_IMAGEURL =
  "https://api.revivify.com/uploads/images/screentypes/";
export const CONCESSIONS_IMAGEURL =
  "https://api.revivify.com/uploads/images/concessions/";
export const API_VERSION = "v1.0";

export const STRIPE_PUB_KEY = "pk_test_51KWhBAFLwZRNwlE3qcalhNmlssJeO8UD2PrgkcJSImboWFWohcQsXkgBtnHpNFYtvzQKmESJ5OogwgR8m8TGADpO00Xe8OyJGP";
